<template>
	<ion-page>
		<ion-header class="white-background-color"></ion-header>
		<ion-content :scroll-events="true" class="ion-text-center ion-no-padding">
			<div
				class="h-wrap ion-text-center ion-padding-vertical ion-margin-vertical mt"
			>
				<h1 class="h-color">
					<span class="blue">AICO</span><span class="red">L</span
					><span class="green">Y</span><span class="yellow">M</span
					><span>PINS</span>
				</h1>
				<h1 class="h-color">
					<span class="red">CATALOGUE</span>
				</h1>
			</div>
			<div class="container">
				<div class="divider"></div>
				<div class="divider-small"></div>

				<article class="ion-padding">
					<div class="flex">
						<img class="author" src="./author.png" alt="author" />
						<div class="ion-text-left ion-padding-horizontal desc">
							<p class="ion-no-margin">Dr. Branislav Delej</p>
							<p class="ion-no-margin">AICO 1st Vice President</p>
						</div>
					</div>

					<main class="ion-text-justify" v-html="intro"></main>
				</article>

				<ion-button @click="done" router-direction="none" class="self --default"
					>Continue</ion-button
				>

				<div class="divider small"></div>

				<p>© 2024 All rights reserved</p>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
import { inject } from 'vue'
import wAxios from '@/plugins/w/axios'

const api = {
	getIntro: () => wAxios.get('v1/intro')
}

export default {
	setup() {
		const ionRouter = inject("navManager")

		return {
			ionRouter,
		}
	},

	data() {
		return {
			intro: null
		}
	},

	methods: {
		done() {
			localStorage.setItem("onboardingDone", true)

			this.ionRouter.navigate({
				routerDirection: "none",
				routerLink: "/app/country-list",
			})
		},
	},

	async created() {
		let data = await api.getIntro()
		this.intro = data.intro
	}
}
</script>
<style lang="sass" scoped>
ion-header
  height: 0
.divider
  height: 2px
  background: rgba(154, 154, 154, 0.4)
.divider-small
  width: 100px
  margin: 0 auto
  height: 2px
  background: black
  position: relative
  top: -2px
  z-index: 100
.red
  color: rgb(243, 30, 69)
.green
  color: rgb(0, 158, 72)
.blue
  color: #0081C8
.yellow
  color: rgb(255, 167, 46)
.h-color
  margin: 0
  padding: 0
h1
  font-weight: normal
  font-size: 60px
.h-wrap
  margin: 15px 0
#welcome
  margin-left: 0 !important
.divider.small
  width: 90%
  max-width: 300px
  margin: 10px auto
  height: 1px !important
.self
  width: 300px
  margin: 20px auto
.flex
  display: flex
  margin: 15px 0
.author
  width: 100%
  max-width: 250px
  height: auto
  align-self: flex-start

.desc
  p
    padding-top: 5px
a .mail
  color: black
  text-decoration: underline
article
  max-width: 960px
  margin: 0 auto
p
  line-height: 1.5
b
  padding-top: 20px
  display: inline-block
  font-size: 18px
.mt
  margin-top: 30px
@media only screen and (max-width: 1100px)
  ion-header
    height: 40px
@media only screen and (max-width: 550px)
  h1
    font-size: 35px
  .flex
    display: block
  .author
    width: 100%
    max-width: 100%
  .desc
    padding: 0 !important
  .white-background-color
    background: white
</style>
